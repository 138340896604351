import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './pages/ProtectedRoute';
import Login from './pages/auth/Login';
import QRCodePage from './pages/QRCodePage';
import Users from './pages/Users';
import Profile from './pages/auth/Profile';
import logo from './assets/favlogo.png';

function App() {
  const initialAuthState = localStorage.getItem('isAuthenticated') === 'true';
  const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const logout = () => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');

    setIsAuthenticated(false); // Update state to reflect logout
  };

  // const user = localStorage.getItem('userName');
  const userid = localStorage.getItem('userId');
  return (
    
    <Router>
      <div className="navbar">
      {/* <img src={logo} alt="App Logo" className="login-logo" /> */}
        <div className="links">
          {!isAuthenticated ? (
            <>
              <Link to="/auth-login">LOGIN</Link>
            </>
          ) : (
            <>
              <Link to="/">APP</Link>
              {parseInt(userid)===1 && <Link to="/users">USERS</Link>}
              <Link to="/profile">PROFILE</Link>
            </>
          )}
        </div>
        <div className="loggedInContainer">
          {/* {isAuthenticated && <h1>{user} </h1>} */}
          {isAuthenticated && <button className='btn' onClick={logout}>Logout</button>}
        </div>
      </div>

      <Routes>
        <Route path="/auth-login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route
          path="/"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <QRCodePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Profile setIsAuthenticated={setIsAuthenticated} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
