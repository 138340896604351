// src/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import logo from '../../assets/logo.png';

function Login({ setIsAuthenticated, setUsernames, setUserId }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Reset previous errors
    setUsernameError("");
    setPasswordError("");
    setError("");

    // Basic validation checks

    if (!username.trim()&&!password.trim()) {
      setUsernameError("Username is required");
      setPasswordError("Password is required");
      return;
    }
    if (!username.trim()) {
      setUsernameError("Username is required");
      return;
    }

    if (!password.trim()) {
      setPasswordError("Password is required");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_END_POINT}login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Invalid login credentials');
      }

      const { token, userId } = await response.json();
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('userName', username);
      setIsAuthenticated(true);
      navigate('/home');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login">
       <img src={logo} alt="App Logo" className="login-logo" />
      <h2>Sign-In</h2>
      <p className="nk-block-des">Access the app using your username/email and password.</p>
      <form onSubmit={handleLogin}>
        <div className="text_area">
          {/* <label htmlFor="username">Username</label> */}
          <input
            type="text"
            id="username"
            className="text_input"
            placeholder="Enter your username"
            value={username}
            autoComplete="off"
            onChange={(e) => setUsername(e.target.value)}
          />
          {usernameError && <p className="error-message" style={{textAlign:"right"}}>{usernameError}</p>}
        </div>
        <div className="text_area">
          {/* <label htmlFor="password">Password</label> */}
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            value={password}
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            className="text_input"
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
        </div>
        <button className="btn btn-block" type="submit">
          Login
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default Login;