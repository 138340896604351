// src/QRCodePage.js
import React, { useState, useRef } from 'react';
import QRCodeLib from 'qrcode'; // Import the QR code library
import '../App.css';

function QRCodePage() {
  const [text, setText] = useState('');
  const [qrCodeUri, setQrCodeUri] = useState('');
  const [qdError, setqdError] = useState('');
  const qrRef = useRef();

  // Function to generate QR code with version 40
  const handleGenerate = async () => {
    if (!text.trim()) {
      setqdError("Please enter the text");
      return;
    }
    setqdError("");

    try {
      const options = {
        errorCorrectionLevel: 'H', // High error correction level
        version: 8,               // QR code version 40
        type: 'image/png',         // Output format as PNG
        maskPattern: 5, 
        
      };

      // Generate QR code as Data URI
      const qrUri = await QRCodeLib.toDataURL(text, options);
      setQrCodeUri(qrUri);
    } catch (err) {
      console.error('Failed to generate QR code', err);
      setqdError("Failed to generate QR code");
    }
  };

  // Function to download the generated QR code as an image
  const handleDownload = () => {
    if (qrRef.current) {
      const link = document.createElement('a');
      link.href = qrCodeUri;
      link.download = 'qr-code.png';
      link.click();
    }
  };

  return (
    <div className="App">
      <div className="homepage-container">
        <h1>QR Code Generator</h1>
        <label htmlFor="text-input" className="input-label">Enter Text:</label>
        <input
          id="text-input"
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text"
          className="text-input"
        />
        {qdError && <p className="error-message" style={{ textAlign: "right" }}>{qdError}</p>}
        <button onClick={handleGenerate}>
          Generate
        </button>
        {qrCodeUri && (
          <div className="qrdisplay" ref={qrRef}>
            <img
              src={qrCodeUri}
              alt="QR Code"
              style={{ width: '300px', height: '300px' }}
            />
          </div>
        )}
        {qrCodeUri && <button onClick={handleDownload}>Download QR Code</button>}
      </div>
    </div>
  );
}

export default QRCodePage;
