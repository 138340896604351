import React from 'react'
import Swal from "sweetalert2";
import axios from "axios";
const Profile = ({setIsAuthenticated}) => {
    const user = localStorage.getItem('userName');
    const userid = localStorage.getItem('userId');

    const handleUpdateUser = () => {
        Swal.fire({
            title: "Update Password",
            html:
                '<input id="swal-input-1" class="swal2-input" type="password" placeholder="Current Password">' +
                '<input id="swal-input-2" class="swal2-input" type="password" placeholder="New Password">',
            focusConfirm: false,
            showCancelButton: true, // Option to cancel
            confirmButtonText: "Save",
            showLoaderOnConfirm: true, // Show loader while confirming
            preConfirm: async () => {
                const password = document.getElementById('swal-input-1').value.trim();
                const newpassword = document.getElementById('swal-input-2').value.trim();
    
                if (!password || !newpassword) {
                    Swal.showValidationMessage('Both fields are required');
                    return false;
                }
    
                try {
                    const response = await axios.put(`${process.env.REACT_APP_END_POINT}profile/${userid}`, {
                        password,
                        newpassword,
                    }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    });
    
                    // Assuming successful response
                    if (response.status === 200) {
                        setIsAuthenticated(false)
                        Swal.fire("Success", "Password updated successfully!", "success");
                        return true;
                    } else {
                        Swal.showValidationMessage('Unexpected error. Please try again.');
                        return false;
                    }
                } catch (error) {
                    console.error('Error updating password:', error);
                    let errorMessage = 'An unexpected error occurred. Please try again.';
    
                    if (error.response && error.response.data) {
                        errorMessage = error.response.data.message || errorMessage;
                    }
    
                    Swal.showValidationMessage(errorMessage);
                    return false;
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
    };
    

    const updateUsername = () => {
        Swal.fire({
            title: "Enter new username",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Update",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const response = await axios.put(`${process.env.REACT_APP_END_POINT}profile/${userid}`, {
                        username: login,
                        email:login
                    }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    });
    
                    // Assuming the API sends back status 200 for success
                    if (response.status !== 200) {
                        throw new Error(response.data.message);
                    }
    
                    return login; // Returning the login to use it in the .then block
    
                } catch (error) {
                    let errorMessage = "Request failed";
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (error.message) {
                        errorMessage = error.message;
                    }
    
                    Swal.showValidationMessage(errorMessage); // Display a friendly error message
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                const newUsername = result.value; // The new username that was confirmed
                localStorage.setItem('userName', newUsername); // Update the username in localStorage
                setIsAuthenticated(false)
                Swal.fire({
                    title: 'Update successful',
                    text: `Your username has been updated to ${newUsername}`,
                    icon: 'success'
                });
            }
        });
    };
    

    return (
        <div className="users-container">
            <div className="users-header">
                <h1>My Profile</h1>
            </div>
            {/* <div className="user-list"> */}

                <div className="user-panel">
                    <p><strong>Email/Username:</strong> {user}</p>
                    <button
                        className="update-btn user-btn"
                        onClick={updateUsername}
                    >
                        Update username
                    </button>
                    <button
                        className="update-btn pwd-btn"
                        onClick={handleUpdateUser}
                    >
                        Update Password
                    </button>

                </div>


            {/* </div> */}
        </div>
    )
}

export default Profile