import React, { useEffect, useState } from 'react';
import './users.css'; // Import your CSS file for UsersPage styling
import Swal from "sweetalert2";
import axios from "axios";

function Users() {
    const [users, setUsers] = useState([]);
    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_END_POINT}users`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const deleteUser = async (userId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_END_POINT}users/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            fetchUsers(); // Refresh user list after deletion
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const confirmDelete = (userId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUser(userId);
                Swal.fire("Deleted!", "The user has been deleted.", "success");
            }
        });
    };



    const handleAddUserPrompt = () => {
        Swal.fire({
            title: "Add User",
            html:
                '<input id="swal-input-1" class="swal2-input" placeholder="Username">' +
                '<input id="swal-input-2" class="swal2-input" placeholder="Password">',
            focusConfirm: false,
            showLoaderOnConfirm: true, // Show loader while confirming
            confirmButtonText: "Save",
            preConfirm: async () => {
                const username = document.getElementById('swal-input-1').value;
                const password = document.getElementById('swal-input-2').value;

                if (!username && !password) {
                    Swal.showValidationMessage('Username and password are required');
                    return false;
                }
                if (!username) {
                    Swal.showValidationMessage('Username is required');
                    return false;
                }
                if (!password) {
                    Swal.showValidationMessage('Password is required');
                    return false;
                }

                try {
                    const response = await axios.post(`h${process.env.REACT_APP_END_POINT}register`, {
                        username,
                        password,
                    }, {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    });
                    console.log('User added:', response.data);
                    fetchUsers(); // Refresh the user list after adding a new user
                    Swal.fire("User added!", "The user has been successfully added.", "success");
                } catch (error) {
                    console.error('Error adding user:', error.response.data);
                    Swal.fire("Error!", error.response.data, "error");
                }
            }
        });
    };


    return (
        <div className="users-container">
            <div className="users-header">
                <h1>App users</h1>
                <button className="add-user-button" onClick={handleAddUserPrompt}>
                    Add User
                </button>
            </div>
            <span className="nk-block-des" style={{ marginBottom: "10px" }}>List of Users accessing the app.</span>
            <div className="user-list">
                {users.map((user, index) => (
                    <div className="user-panel" key={user.id}>
                        <div className="user-badge">0{index + 1}</div>
                        <p>Email/Username: {user.email}</p>
                        {user.id !== 1 &&
                            <button
                                className="delete-button"
                                onClick={() => confirmDelete(user.id)}
                            >
                                Delete
                            </button>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Users;
